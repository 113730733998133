import { WalletProvider } from '@/dependencies/wallets/Web3Provider';
import FXPoolDeployerAbi from '@/lib/abi/FXPoolDeployer.json';
import { isSameAddress } from '@/lib/utils';
import { configService } from '@/services/config/config.service';
import { TransactionBuilder } from '@/services/web3/transactions/transaction.builder';
import { defaultAbiCoder, Interface } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import { ContractReceipt } from '@ethersproject/contracts';
import { JsonRpcProvider, TransactionResponse } from '@ethersproject/providers';
import { parseEther } from '@ethersproject/units';

interface ViewDepositNoLiquidityReturn {
  expectedShares: BigNumber;
  baseTokenAmount: BigNumber;
  quoteTokenAmount: BigNumber;
}

export default class FXPoolService {
  public getFXPoolDeployer(quoteTokenAddress: string) {
    const factories = configService.network.pools.FXPoolFactories || {};

    const targetIndex = Object.keys(factories).findIndex(quoteToken =>
      isSameAddress(quoteToken, quoteTokenAddress)
    );

    return targetIndex >= 0 ? Object.values(factories)[targetIndex] : null;
  }

  public async previewCreate(
    provider: WalletProvider,
    fxPoolDeployerAddress: string,
    depositNumeraire: string,
    baseTokenAddress: string,
    baseOracleAddress: string
  ) {
    const txBuilder = new TransactionBuilder(provider.getSigner());

    const response =
      await txBuilder.contract.callStatic<ViewDepositNoLiquidityReturn>({
        contractAddress: fxPoolDeployerAddress,
        abi: FXPoolDeployerAbi,
        action: 'viewDepositNoLiquidity',
        params: [
          parseEther(depositNumeraire),
          baseTokenAddress,
          baseOracleAddress,
        ],
      });

    return response;
  }

  public async create(
    provider: WalletProvider,
    fxPoolDeployerAddress: string,
    depositNumeraire: string,
    baseTokenAddress: string,
    baseOracleAddress: string,
    protocolFee: string,
    params: {
      alpha: string;
      beta: string;
      max: string;
      epsilon: string;
      lambda: string;
    }
  ): Promise<TransactionResponse> {
    const callData = defaultAbiCoder.encode(
      [
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'string',
      ],
      [
        protocolFee,
        parseEther(params.alpha),
        parseEther(params.beta),
        parseEther(params.max),
        parseEther(params.epsilon),
        parseEther(params.lambda),
        '', // @todo: ask user to fill this suffix
      ]
    );

    const txBuilder = new TransactionBuilder(provider.getSigner());

    return await txBuilder.contract.sendTransaction({
      contractAddress: fxPoolDeployerAddress,
      abi: FXPoolDeployerAbi,
      action: 'newFXPool',
      params: [
        baseTokenAddress,
        baseOracleAddress,
        parseEther(depositNumeraire),
        callData,
      ],
    });
  }

  public async retrievePoolIdAndAddress(
    provider: WalletProvider | JsonRpcProvider,
    createHash: string
  ) {
    const receipt = (await provider.getTransactionReceipt(
      createHash
    )) as ContractReceipt;
    if (!receipt) return null;

    const fxPoolInterface = new Interface(FXPoolDeployerAbi);
    const newFXPoolEventTopic = fxPoolInterface.getEventTopic('NewFXPool');

    const newFXPoolLog = receipt.logs.find(
      log => log.topics[0] === newFXPoolEventTopic
    );

    if (newFXPoolLog) {
      const decodedEvent = fxPoolInterface.decodeEventLog(
        'NewFXPool',
        newFXPoolLog.data,
        newFXPoolLog.topics
      );
      return {
        id: decodedEvent.id,
        address: decodedEvent.fxpool,
      };
    }

    return null;
  }
}
