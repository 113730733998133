import { PoolSeedToken } from '@/composables/pools/usePoolCreation';
import { POOLS } from '@/constants/pools';
import { WalletProvider } from '@/dependencies/wallets/Web3Provider';
import ComposableStablePoolFactoryAbi from '@/lib/abi/ComposableStablePoolFactory.json';
import { isSameAddress, scale } from '@/lib/utils';
import { configService } from '@/services/config/config.service';
import { TransactionBuilder } from '@/services/web3/transactions/transaction.builder';
import {
  Vault__factory,
  WeightedPool__factory,
  WeightedPoolFactory__factory,
} from '@balancer-labs/typechain';
import { defaultAbiCoder } from '@ethersproject/abi';
import { BigNumber as EPBigNumber } from '@ethersproject/bignumber';
import { AddressZero } from '@ethersproject/constants';
import { JsonRpcProvider, TransactionResponse } from '@ethersproject/providers';
import { randomBytes } from '@ethersproject/random';
import BigNumber from 'bignumber.js';

type Address = string;

export interface CreatePoolReturn {
  id: string;
  address: Address;
}

const JOIN_KIND_INIT = 0;

export interface JoinPoolRequest {
  assets: Address[];
  maxAmountsIn: string[];
  userData: any;
  fromInternalBalance: boolean;
}

export default class ComposableStablePoolService {
  public async create(
    provider: WalletProvider,
    name: string,
    symbol: string,
    amplification: string,
    swapFee: string,
    tokens: PoolSeedToken[],
    owner: Address
  ): Promise<TransactionResponse> {
    if (!owner.length) return Promise.reject('No pool owner specified');

    const factoryAddress =
      configService.network.addresses.composableStablePoolFactory;
    if (!factoryAddress) {
      return Promise.reject(
        'ComposableStablePoolFactory address is not defined for this network'
      );
    }

    const tokenAddresses: Address[] = tokens.map((token: PoolSeedToken) => {
      return token.tokenAddress;
    });

    const swapFeeScaled = scale(new BigNumber(swapFee), 18);
    const rateProviders = Array(tokenAddresses.length).fill(POOLS.ZeroAddress);
    const rateCacheDurations = Array(tokenAddresses.length).fill(0);
    const exemptFromYieldProtocolFeeFlag = false;
    const salt = randomBytes(32);

    const params = [
      name,
      symbol,
      tokenAddresses,
      amplification,
      rateProviders,
      rateCacheDurations,
      exemptFromYieldProtocolFeeFlag,
      swapFeeScaled.toString(),
      owner,
      salt,
    ];

    const txBuilder = new TransactionBuilder(provider.getSigner());
    return await txBuilder.contract.sendTransaction({
      contractAddress: factoryAddress,
      abi: ComposableStablePoolFactoryAbi,
      action: 'create',
      params,
    });
  }

  public async retrievePoolIdAndAddress(
    provider: WalletProvider | JsonRpcProvider,
    createHash: string
  ): Promise<CreatePoolReturn | null> {
    const receipt = await provider.getTransactionReceipt(createHash);
    if (!receipt) return null;

    const factoryAddress =
      configService.network.addresses.composableStablePoolFactory;
    if (!factoryAddress) return null;

    const weightedPoolFactoryInterface =
      WeightedPoolFactory__factory.createInterface();

    const poolCreationEvent = receipt.logs
      .filter(log => log.address.toLowerCase() === factoryAddress.toLowerCase())
      .map(log => {
        try {
          return weightedPoolFactoryInterface.parseLog(log);
        } catch {
          return null;
        }
      })
      .find(parsedLog => parsedLog?.name === 'PoolCreated');

    if (!poolCreationEvent) return null;
    const poolAddress = poolCreationEvent.args.pool;

    const pool = WeightedPool__factory.connect(poolAddress, provider);
    const poolId = await pool.getPoolId();

    return {
      id: poolId,
      address: poolAddress,
    };
  }

  public async initJoin(
    provider: WalletProvider,
    poolId: string,
    sender: Address,
    receiver: Address,
    tokenAddresses: Address[],
    initialBalancesString: string[]
  ): Promise<TransactionResponse> {
    const vaultAddress = configService.network.addresses.vault;
    const vault = Vault__factory.connect(vaultAddress, provider);
    const [poolTokens, poolAmounts] = await vault.getPoolTokens(poolId);

    const amountsIn: string[] = [];
    const maxAmountsIn: string[] = [];
    let sortedTokenIndex = 0;
    for (let i = 0; i < poolTokens.length; i++) {
      if (tokenAddresses.includes(poolTokens[i])) {
        // regular pool tokens
        amountsIn.push(initialBalancesString[sortedTokenIndex]);
        maxAmountsIn.push(initialBalancesString[sortedTokenIndex]);
        sortedTokenIndex++;
      } else {
        // BPT token
        amountsIn.push(poolAmounts[i].toString());

        const maxInt = new BigNumber(`${Number.MAX_SAFE_INTEGER}`);
        const scaledMaxInt = scale(maxInt, 18);
        const scaledRoundedMaxInt = scaledMaxInt.toFixed(
          0,
          BigNumber.ROUND_FLOOR
        );
        maxAmountsIn.push(scaledRoundedMaxInt);
      }
    }

    const initUserData = defaultAbiCoder.encode(
      ['uint256', 'uint256[]'],
      [JOIN_KIND_INIT, amountsIn]
    );

    const value = this.value(amountsIn, poolTokens);

    const parsedPoolTokens = this.parseTokensIn(poolTokens);

    const joinPoolRequest: JoinPoolRequest = {
      assets: parsedPoolTokens,
      maxAmountsIn: maxAmountsIn,
      userData: initUserData,
      fromInternalBalance: false,
    };

    const txBuilder = new TransactionBuilder(provider.getSigner());
    return await txBuilder.contract.sendTransaction({
      contractAddress: vaultAddress,
      abi: Vault__factory.abi,
      action: 'joinPool',
      params: [poolId, sender, receiver, joinPoolRequest],
      options: { value },
    });
  }

  private value(amountsIn: string[], tokensIn: string[]): EPBigNumber {
    let value = '0';
    const nativeAsset = configService.network.nativeAsset;

    amountsIn.forEach((amount, i) => {
      if (tokensIn[i] === nativeAsset.address) {
        value = amount;
      }
    });

    return EPBigNumber.from(value);
  }

  private parseTokensIn(tokensIn: string[]): string[] {
    const nativeAsset = configService.network.nativeAsset;

    return tokensIn.map(address =>
      isSameAddress(address, nativeAsset.address) ? AddressZero : address
    );
  }
}
