import ComposableStablePoolService from './composable-stable-pool.service';
import FXPoolService from './fx-pool.service';
import WeightedPoolService from './weighted-pool.service';

export default class PoolsService {
  weighted: WeightedPoolService;
  fx: FXPoolService;
  composableStable: ComposableStablePoolService;

  constructor() {
    this.weighted = new WeightedPoolService();
    this.fx = new FXPoolService();
    this.composableStable = new ComposableStablePoolService();
  }
}

export const poolsService = new PoolsService();
